'use client';

import { Session } from 'next-auth';
import { SessionProvider as Provider, signOut } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

export default function SessionProvider({ session, children }: { session: Session | null, children: React.ReactNode }) {
  // TODO: Refactor this when backend is ready
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient) {
      const localRememberMe = localStorage.getItem('rememberMe');
      const sessionRememberMe = sessionStorage.getItem('rememberMe');

      if (session && !localRememberMe && !sessionRememberMe) {
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('rememberMe');
        signOut();
      }
    }
  }, [isClient, session]);

  if (!isClient) {
    return null;
  }

  return (
    <Provider session={session}>
      {children}
    </Provider>
  );
}
